/* استایل‌های قبلی */
.product-detail {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-top: 120px;
}

.product-detail-left,
.product-detail-center,
.product-detail-right {
    width: 30%;
}

.product-detail-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
}

.price-card {
    width: 100%;
    padding: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e0e0e0; /* خطوط کم‌رنگ */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* سایه */
    border-radius: 10px; /* گرد کردن گوشه‌ها */
}

.price-card p {
    margin: 5px 0; /* فاصله بین پاراگراف‌ها */
}

.add-to-cart-button {
    width: 100%; /* دکمه افزودن به سبد خرید در وسط و تمام عرض کارت */
    background-color: #ff5722; /* رنگ پس‌زمینه */
    border: none; /* حذف حاشیه */
    padding: 10px 0; /* فاصله عمودی */
    font-size: 16px; /* اندازه فونت */
    color: #fff; /* رنگ فونت */
    border-radius: 5px; /* گرد کردن گوشه‌ها */
    cursor: pointer; /* تغییر نشانگر ماوس */
    text-align: center;
}

.product-detail-center h2 {
    margin-bottom: 10px;
}

.product-detail-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
}

.product-detail-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.thumbnail-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
}

.product-detail-menu {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.product-detail-menu button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
}

.product-suggestions {
    margin-top: 20px;
    text-align: center;
}

.suggestion-list {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    justify-content: center;
    padding: 10px 0;
}

.suggestion-item {
    flex: 0 0 auto;
    text-align: center;
}

.suggestion-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

/* استایل‌های دسکتاپ */
.header-mobile {
    display: none;
}

/* استایل‌های موبایل */
@media (max-width: 768px) {
    .notification-bar {
        display: none;
    }

    .header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1001;
    }

    .header-icon {
        font-size: 24px;
        margin: 0 10px;
        cursor: pointer;
    }

    .mobile-search-input {
        display: none; /* حذف input جستجو */
    }

    .search-visible .mobile-search-input {
        display: block;
    }

    .search-visible .header-icon {
        display: none;
    }

    .product-detail {
        padding-top: 60px; /* فضای بالا برای هدر موبایل */
        padding-bottom: 100px; /* فضای پایین برای فوتر موبایل */
        overflow-y: auto; /* فعال کردن اسکرول برای محتوای اصلی */
    }

    .product-detail-left,
    .product-detail-center,
    .product-detail-right {
        width: 100%;
    }

    .mobile-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid #e0e0e0;
        padding: 15px 20px; /* افزایش فضای بالا و پایین و فاصله داخلی */
        z-index: 1001;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* سایه برای جدا شدن از محتوا */
    }

    .add-to-cart-button-mobile {
        flex: 1;
        margin-left: 30px; /* اضافه کردن فاصله بین دکمه و قیمت */
        background-color: #ff5722;
        border: none;
        color: #fff;
        padding: 20px; /* افزایش ارتفاع دکمه */
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        font-size: 18px; /* افزایش سایز فونت برای بهتر دیده شدن */
        display: flex;
        align-items: center; /* مرکزچین کردن نوشته در دکمه */
        justify-content: center; /* مرکزچین کردن نوشته در دکمه */
    }

    .mobile-price {
        font-size: 18px; /* افزایش سایز فونت برای قیمت */
        color: #000;
        font-weight: bold;
    }
}